import React from 'react';
import HeroArea from '../Component/HeroArea';

const Home = () => {
  return (
    <>
      <HeroArea />
    </>
  );
};

export default Home;
